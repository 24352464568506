h2 {
  font-size: 1.7rem;
  text-align: center;
  color: black;
  margin-top: 0.5rem;
}

.form-control {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.text-input {
  flex-grow: 1;
  padding: 5px 5px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  font-size: 1rem;
}
.text-input:focus {
  outline: none;
}
.submit-btn {
  background: rgb(4, 92, 16);
  color: white;
  cursor: pointer;
  border: transparent;
  font-size: 1rem;
}
