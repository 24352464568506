.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 0.25rem 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  font-size: 1rem;
}
.title {
  color: black;
  font-weight: bold;
}
.btn-div{
  padding: 1rem 0 0.5rem 0;
}
.btn {
  font-size: 1.5rem;
  margin-left: 0.8rem;
  cursor: pointer;
  color: #000;
  
}
