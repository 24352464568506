header {
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.logo {
  display: flex;
  align-items: center;
}
.logo span {
  font-size: 22px;
  margin-left: 7px;
  cursor: pointer;
}
.theme-switch span{
    font-size: 20px;
    margin-bottom: 100px;
    cursor: pointer;
    margin-left: 10px;   
}